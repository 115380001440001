<template>
<div
    v-if="isPortfolioReady"
    :key="`key_${key}`"
>
    <div class="kt-container kt-container-fullscreen overflow-hidden py-0 my-0">
        <div class="kt-portlet kt-fullscreen-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <span
                        v-if="saving"
                        class="kt-portlet__head-icon"
                        style="width: 30px"
                    >
                        <div class="kt-spinner kt-spinner--md kt-spinner--primary" />
                    </span>
                    <h3 class="kt-portlet__head-title">
                        <span>
                            Edit Assignment
                        </span>

                        <small v-if="selectedCourse">
                            {{ selectedCourse.courseSectionTitle || selectedCourse.name }}
                        </small>

                        <span v-if="courseWork && courseWork.isExternal" class="ml-4">
                            <a
                                href="#"
                                class="btn btn-sm btn-google"
                                style="cursor: default;"
                                :disabled="true"
                            >
                                <i class="socicon-google" />Imported
                            </a>
                        </span>
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div
                        v-if="activeCourse && activeCourse.assigned"
                        class="kt-portlet__head-actions"
                    >
                        <button
                            :disabled="saving"
                            class="btn btn-clean btn-md mr-2"
                            @click.stop.prevent="save(false)"
                        >
                            Save to this course
                        </button>
                        <button
                            :disabled="saving"
                            class="btn btn-primary btn-md"
                            @click.stop.prevent="save(true)"
                        >
                            Save to all
                        </button>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="kt-section mb-0">
                    <div class="kt-section__body">
                        <div class="row form-group mb-3">
                            <ul
                                class="nav nav-pills nav-pills-md nav-pills-label mb-0"
                                role="tablist"
                            >
                                <li
                                    v-for="course in courses"
                                    :key="`ass-course-${course.courseSectionId}`"
                                    class="nav-item mx-0"
                                >
                                    <label
                                        :class="tabClass(course)"
                                        class=""
                                    >
                                        <template v-if="!course.saving">
                                            <input
                                                :checked="course.assigned"
                                                type="checkbox"
                                            >
                                            <span @click.stop.prevent="assignCourseToggle(course.courseSectionId)" />
                                        </template>
                                        <div
                                            v-else
                                            class="mr-2 p-0 kt-spinner kt-spinner--sm kt-spinner--brand"
                                        />

                                        <router-link
                                            :to="{
                                                name: $route.name,
                                                params: $route.params,
                                                query: {
                                                    courseSectionId: course.courseSectionId
                                                },
                                            }"
                                            :class="[
                                                $route.query.courseSectionId == course.courseSectionId ? 'active kt-font-bold' : '',
                                                'nav-link pl-5'
                                            ]"
                                        >
                                            {{ course.courseSectionTitle || course.name }}
                                        </router-link>
                                    </label>
                                </li>
                            </ul>
                        </div>

                        <LocalCourseWork
                            ref="localCourseWork"
                            class="tab-container"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import moment from 'moment';
import LocalCourseWork from '../components/LocalCourseWork.vue';
import courseMixins, { getCourses, CourseFilter } from '../store/mixins/courseMixins';
import { courseWorkMixins } from '../store/mixins/courseWorkMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import { getSessionUser } from '../store/mixins/userMixins';
import Types from '../store/Types';

export default Vue.extend({
    name: 'LocalCourseAssignmentEdit',
    components: {
        LocalCourseWork,
    },
    mixins: [
        courseMixins,
        courseWorkMixins,
        teacherMixins,
        portfolioMixins,
    ],
    data() {
        return {
            key: 0,
            saving: false,
        };
    },
    computed: {
        courses() {
            return this.$store.state.database.assignmentCourseSections;
        },
        dirty() {
            return this.$store.state.database.assignmentIsDirty;
        },
        courseWork() {
            return this.$store.state.database.assignment;
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        assignmentCourseSections() {
            return this.$store.state.database.assignmentCourseSections;
        },
        primaryTeacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        primaryCourse() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        primaryCourseWork() {
            const cw = this.$_courseWorkMixins_getCourseWorkFromRoute();
            return cw;
        },
        courseWorks() {
            const { primaryCourseWork } = this;
            if (!primaryCourseWork) return [];
            return this.$store.state.database.courseWork.filter((cw) => cw.linkingGuid == primaryCourseWork.linkingGuid);
        },
        teacherCourses() {
            if (!this.primaryTeacher) return [];
            const { database } = this.$store.state;
            const { schoolStaffId } = this.primaryTeacher;
            const session = getSessionUser(this.$store.state);
            const courses = getCourses(database, new CourseFilter({ schoolStaffId, session }));
            return courses;
        },
        selectedCourse() {
            const { teacherCourses } = this;
            if (this.$route.query.courseSectionId) {
                const { courseSectionId } = this.$route.query;
                return teacherCourses.find((cw) => cw.courseSectionId == courseSectionId) || null;
            }
            return null;
        },
        gradeTemplate() {
            if (!this.selectedCourse) return null;
            return this.selectedCourse.gradeTemplate;
        },
        activeCourse() {
            const { courseSectionId } = this.$route.query;
            return this.courses.find((c) => c.courseSectionId == courseSectionId);
        },
    },
    watch: {
        primaryCourseWork() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        save(saveToAll = false) {
            const { localCourseWork } = this.$refs;
            const valid = localCourseWork.isValid();
            if (!valid) return;

            if (this.saving) return;
            this.saving = true;

            const { showError, showAlert, formatDueDates } = this;
            const { $store, assignmentCourseSections, courseWork } = this;
            const courseWorks = assignmentCourseSections
                .filter((c) => {
                    if (saveToAll) return c.assigned;
                    return c.courseSectionId == courseWork.courseSectionId;
                })
                .map((c) => {
                    const existing = this.courseWorks.find((cw) => cw.courseSectionId == c.courseSectionId);
                    if (!existing) {
                        return null;
                    }
                    const payload = { ...existing };
                    Object.keys(courseWork).forEach((key) => {
                        if (payload.hasOwnProperty(key)) payload[key] = courseWork[key];
                    });
                    payload.courseWorkId = existing.courseWorkId;
                    payload.courseSectionId = existing.courseSectionId;
                    return formatDueDates(payload);
                })
                .filter((cw) => cw != null);

            if (courseWorks.length == 0) {
                showError('No assignments selected');
                this.saving = false;
                return;
            }

            const { gradeTemplate } = this;
            const action = Types.actions.EDIT_LOCAL_COURSE_WORK;
            const v = this;
            $store.dispatch(action, {
                courseWorks,
                gradeTemplate,
                callback(err) {
                    v.saving = false;
                    if (err) return showError(err);
                    showAlert('Assignments Saved', null, 'success');
                },
            });
        },
        tabClass(course) {
            const classes = ['kt-checkbox p-2 m-0 w-100'];
            const isActive = this.$route.query.courseSectionId == course.courseSectionId;
            if (isActive) {
                classes.push('border-left border-top border-right');
                if (course.assigned) {
                    classes.push('kt-checkbox--solid kt-checkbox--primary');
                } else {
                    classes.push('kt-checkbox--solid kt-checkbox--light');
                }
            } else {
                classes.push('border-bottom');
                if (course.assigned) {
                    classes.push('kt-checkbox--primary');
                } else {
                    classes.push('kt-checkbox--solid');
                }
            }

            return classes;
        },
        refreshCourseAssignments(courseWork) {
            const assignmentCourseSections = this.courses.map((c) => {
                if (c.courseSectionId == courseWork.courseSectionId) {
                    const section = { ...c };
                    section.saving = false;
                    section.assigned = !courseWork.deleted;
                    section.deleted = courseWork.deleted;
                    return section;
                }
                return c;
            });
            this.$store.commit(Types.mutations.SET_ASSIGNMENT_COURSE_SECTIONS, { assignmentCourseSections });
        },
        formatDueDates(cw) {
            const courseWork = { ...cw };
            const fullFormat = 'YYYY-MM-DD HH:mm:ss';
            const dateFormat = 'YYYY-MM-DD';
            const timeFormat = 'HH:mm:ss';
            // format dueDate with time
            if (courseWork.dueDate) {
                const dueDate = moment(`${courseWork.dueDate} ${courseWork.dueTime || '12:00:00 AM'}`, fullFormat);
                courseWork.dueDate = dueDate.format(dateFormat);
                courseWork.dueTime = dueDate.format(timeFormat);
            }

            // format publishDate with time
            if (courseWork.publishDate) {
                const publishDate = moment(`${courseWork.publishDate} ${courseWork.publishTime || '12:00:00 AM'}`, fullFormat);
                courseWork.publishDate = publishDate.format(dateFormat);
                courseWork.publishTime = publishDate.format(timeFormat);
            }
            return courseWork;
        },
        assignCourseToggle(courseSectionId) {
            const { courses, $store, primaryCourseWork } = this;

            const { localCourseWork } = this.$refs;
            const valid = localCourseWork.isValid();
            if (!valid) return;

            const assignmentCourseSections = courses.map((c) => {
                if (c.courseSectionId == courseSectionId) {
                    const course = { ...c };
                    course.saving = true;
                    course.assigned = !course.assigned;
                    return course;
                }
                return c;
            });

            const course = assignmentCourseSections.find((c) => c.courseSectionId == courseSectionId);
            if (!course) return;

            $store.commit(Types.mutations.SET_ASSIGNMENT_COURSE_SECTIONS, { assignmentCourseSections });

            let courseWork = this.courseWorks.find((cw) => cw.courseSectionId == courseSectionId);
            if (courseWork) {
                courseWork.deleted = !course.assigned;
                return this.editCourseWork(courseWork);
            }

            courseWork = this.formatDueDates({ ...primaryCourseWork });
            courseWork.deleted = !course.assigned;
            courseWork.courseSectionId = courseSectionId;
            delete courseWork.courseWorkId;
            this.addCourseWork(courseWork);
        },
        addCourseWork(courseWork) {
            const { $store, refreshCourseAssignments } = this;
            const { showError, gradeTemplate } = this;
            const action = Types.actions.ADD_LOCAL_COURSE_WORK;
            $store.dispatch(action, {
                courseWorks: [courseWork],
                gradeTemplate,
                callback(err) {
                    if (err) return showError(err);
                    refreshCourseAssignments(courseWork);
                },
            });
        },
        editCourseWork(courseWork) {
            const { $store, refreshCourseAssignments } = this;
            const { showError, gradeTemplate } = this;
            const action = Types.actions.EDIT_LOCAL_COURSE_WORK;
            $store.dispatch(action, {
                courseWorks: [courseWork],
                gradeTemplate,
                callback(err) {
                    if (err) return showError(err);
                    refreshCourseAssignments(courseWork);
                },
            });
        },
        populate() {
            const { teacherCourses, primaryCourseWork, courseWorks } = this;
            if (!primaryCourseWork) return;
            // put active on top

            const assignmentCourseSections = teacherCourses
                .map((c) => {
                    const course = { ...c };
                    const exists = courseWorks.find((cw) => cw.courseSectionId == c.courseSectionId);
                    course.assigned = exists ? !exists.deleted : false;
                    return course;
                })
                .sort((a, b) => (`${a.periodSort}` > `${b.periodSort}` ? 1 : -1));
            // push active course to top
            const index = assignmentCourseSections.findIndex((c) => c.extCourseSectionId == this.$route.params.extCourseSectionId);
            if (index > -1) assignmentCourseSections.unshift(assignmentCourseSections.splice(index, 1)[0]);

            this.$store.commit(Types.mutations.SET_ASSIGNMENT_COURSE_SECTIONS, { assignmentCourseSections });
            if (!this.$route.query.courseSectionId && assignmentCourseSections.length > 0) {
                this.$router.replace({
                    name: this.$route.name,
                    params: this.$route.params,
                    query: {
                        courseSectionId: assignmentCourseSections[0].courseSectionId,
                    },
                });
            }
        },
    },
});

</script>
<style scoped>

ul {
    flex-wrap: nowrap;
    overflow-x: scroll;
}
li.nav-item {
    width: 160px;
}

li.nav-item a.nav-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

label.kt-checkbox span {
    margin-top: 16px;
    margin-left: 18px;
}
label.kt-checkbox div.kt-spinner {
    position: absolute;
    top: 26px;
    left: 20px;
}

.tab-container {
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    padding: 20px;
}

</style>
