var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isPortfolioReady
    ? _c("div", { key: `key_${_vm.key}` }, [
        _c(
          "div",
          {
            staticClass:
              "kt-container kt-container-fullscreen overflow-hidden py-0 my-0",
          },
          [
            _c("div", { staticClass: "kt-portlet kt-fullscreen-portlet" }, [
              _c("div", { staticClass: "kt-portlet__head" }, [
                _c("div", { staticClass: "kt-portlet__head-label" }, [
                  _vm.saving
                    ? _c(
                        "span",
                        {
                          staticClass: "kt-portlet__head-icon",
                          staticStyle: { width: "30px" },
                        },
                        [
                          _c("div", {
                            staticClass:
                              "kt-spinner kt-spinner--md kt-spinner--primary",
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c("h3", { staticClass: "kt-portlet__head-title" }, [
                    _c("span", [_vm._v(" Edit Assignment ")]),
                    _vm.selectedCourse
                      ? _c("small", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedCourse.courseSectionTitle ||
                                  _vm.selectedCourse.name
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.courseWork && _vm.courseWork.isExternal
                      ? _c("span", { staticClass: "ml-4" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-sm btn-google",
                              staticStyle: { cursor: "default" },
                              attrs: { href: "#", disabled: true },
                            },
                            [
                              _c("i", { staticClass: "socicon-google" }),
                              _vm._v("Imported "),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                  _vm.activeCourse && _vm.activeCourse.assigned
                    ? _c("div", { staticClass: "kt-portlet__head-actions" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-clean btn-md mr-2",
                            attrs: { disabled: _vm.saving },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.save(false)
                              },
                            },
                          },
                          [_vm._v(" Save to this course ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-md",
                            attrs: { disabled: _vm.saving },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.save(true)
                              },
                            },
                          },
                          [_vm._v(" Save to all ")]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c("div", { staticClass: "kt-section mb-0" }, [
                  _c(
                    "div",
                    { staticClass: "kt-section__body" },
                    [
                      _c("div", { staticClass: "row form-group mb-3" }, [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "nav nav-pills nav-pills-md nav-pills-label mb-0",
                            attrs: { role: "tablist" },
                          },
                          _vm._l(_vm.courses, function (course) {
                            return _c(
                              "li",
                              {
                                key: `ass-course-${course.courseSectionId}`,
                                staticClass: "nav-item mx-0",
                              },
                              [
                                _c(
                                  "label",
                                  { class: _vm.tabClass(course) },
                                  [
                                    !course.saving
                                      ? [
                                          _c("input", {
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: course.assigned,
                                            },
                                          }),
                                          _c("span", {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.assignCourseToggle(
                                                  course.courseSectionId
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      : _c("div", {
                                          staticClass:
                                            "mr-2 p-0 kt-spinner kt-spinner--sm kt-spinner--brand",
                                        }),
                                    _c(
                                      "router-link",
                                      {
                                        class: [
                                          _vm.$route.query.courseSectionId ==
                                          course.courseSectionId
                                            ? "active kt-font-bold"
                                            : "",
                                          "nav-link pl-5",
                                        ],
                                        attrs: {
                                          to: {
                                            name: _vm.$route.name,
                                            params: _vm.$route.params,
                                            query: {
                                              courseSectionId:
                                                course.courseSectionId,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              course.courseSectionTitle ||
                                                course.name
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("LocalCourseWork", {
                        ref: "localCourseWork",
                        staticClass: "tab-container",
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }